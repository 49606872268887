<template>
  <div>
    <header>
      <Navbar />
    </header>
    <main class="policy">
      <div class="bg-gradient">
        <div class="container jumbotron">
          <div class="policy-title">
            <h3 class="font-small-24">
              隱私權保護政策
            </h3>
            <p class="font-small-36 font-lg-60">
              Privacy Policy
            </p>
          </div>
        </div>
      </div>
      <div class="container policy-content">
        <p>
          美而快電商金融科技股份有限公司(以下簡稱本公司)為保障您的個人資料及隱私權，特訂定本網站及相關應用程式(以下簡稱本平台)的隱私權保護政策(以下簡稱本政策)，讓您瞭解本公司對於您的個人資料及隱私權之尊重與保護，並告訴您有關您個人資料權利義務之行使及負責。
        </p>
        <p>
          若您為未滿十八歲或無完全行為能力之人，除應符合上述規定外，請於您的法定代理人或監護人閱讀、瞭解並同意本政策及其後修改、變更後之所有內容後，方得使用或繼續使用本平台，否則請立即停止使用。
        </p>
        <p>
          當您進入本平台及使用本平台之各項服務(包括但不限於瀏覽本平台、參加本平台活動或服務、就本公司之服務提出申請及進行線上交易等)時，本公司依本政策蒐集、處理與利用您所提供之個人資料。您於使用本平台或點選同意本政策時均已清楚瞭解及知悉本公司蒐集、處理或利用您本人個人資料之目的及用途，並同意以電子相關方式表示及同意本政策之內容，且您提供與本公司之資料若包含您以外第三人之個人資料時，您已以適當方式使該第三人知悉其個人資料會提供與本公司於本政策範圍內，進行蒐集、處理或利用。
        </p>
        <h5>
          第壹條 資料蒐集之目的
        </h5>
        <ul>
          <li class="py-1">
            (一)
            辦理本公司經營於營業登記項目或組織章程所定之業務(包括但不限於租賃、分期付款買賣、資金貸與、應收帳款受讓暨管理等相關業務)；
          </li>
          <li class="py-1">
            (二)
            契約、類似契約或其他法律關係事務；核貸與授信業務；帳務管理及債權交易業務；授信業務；債權整貼現及收買業務；徵信業務(含上下游及同業間彼此照會、租賃同業、關係企業、票信等照會之徵信行為)；代理與仲介業務；網路購物及其他電子商務服務；其他諮詢與顧問服務；
          </li>
          <li class="py-1">
            (三)
            調查、統計與研究分析；消費者、客戶管理與服務；消費者保護；成員名冊之內部管理；資(通)訊服務；資(通)訊與資料庫管理；資通安全與管理；
          </li>
          <li class="py-1">
            (四)
            行銷(含本公司及關係企業及其業務委外機構等，於行銷或合作目的之範圍內使用等)。
          </li>
        </ul>
        <h5>
          第貳條 蒐集之個人資料類別
        </h5>
        <p>
          依個人資料保護法第二條第一項所定義之個人資料，包括但不限於姓名、身分證號、性別、生日、手機號碼、電子郵件信箱、聯絡方式、影像、家庭情形、社會情況、教育、考選、技術或其他專業、受僱情形、財務資料、身分證明文件、電子簽章、提供網路身分認證或申辦查詢服務之紀錄(如操作時間、IP位址、設備資訊、瀏覽器種類、網頁瀏覽軌跡紀錄、Cookies)及其他任何可辨識資料為本人者等資訊；亦包括與本公司相關業務之申請書或契約書內容及其他具有正當合理關聯之紀錄，但仍以本公司與您往來之相關業務所實際蒐集之個人資料為準。
        </p>
        <h5>
          第參條 個人資料利用之期間、對象、地區及方式
        </h5>
        <ul>
          <li>
            <p class="mb-1">
              (一) 期間：
            </p>
            <p class="policy-indent">
              個人資料蒐集之特定目的存續期間、依相關法令或契約約定之保存期限（如：商業會計法等）或本公司因執行業務所必須之保存期間。
            </p>
          </li>
          <li>
            <p class="mb-1">
              (二) 對象：
            </p>
            <p class="policy-indent">
              本公司、關係企業、子公司及其日後成立之子公司或關係企業、財團法人金融聯合徵信中心、票據交換所、財金資訊公司、代理收付款項業務機構、業務委外機構、未受中央目的事業主管機關限制之個人資料之接收者、本公司之共同行銷或交互運用客戶之機構、本公司合作推廣對象、與本公司有業務往來之機構、依法有調查權機關或金融監理機關或其他法令得蒐集、處理及利用資料之人。您同意本公司得基於徵信之需要而代理本人並使用本人之個人資料暨銀行資料向發卡銀行及電信公司等為相關查詢。若您自行向財團法人金融聯合徵信中心申請查詢並調閱取得信用報告後，您再自行將信用報告交付本公司時，您同意本公司得以在與您業務往來或您所保證之主債務人往來評估範圍內利用您所交付前揭報告資料。
            </p>
          </li>
          <li>
            <p class="mb-1">
              (三) 地區：
            </p>
            <p class="policy-indent">
              前揭利用對象之營業處所所在地及未受中央目的事業主管機關限制之個人資料之接收者所在地。
            </p>
          </li>
          <li>
            <p class="mb-1">
              (四) 方式：
            </p>
            <p class="policy-indent">
              以自動化機器或其他非自動化方式之利用方式(例如使用電子文件、紙本或其他合於當時科技之適當方式)或符合個人資料保護法第20條規定之利用。
            </p>
          </li>
        </ul>
        <h5>
          第肆條
          您得透過書面或本公司同意之其他方式，依據個資法第三條規定，就本公司保有您之個人資料申請行使下列權利：
        </h5>
        <ul>
          <li class="py-1">
            (一)
            得向本公司查詢、請求閱覽或請求製給複製本，而本公司依法得酌收必要成本費用。
          </li>
          <li class="py-1">
            (二) 得向本公司請求補充或更正，惟依法您應為適當之釋明。
          </li>
          <li class="py-1">
            (三)
            得向本公司請求停止蒐集、處理或利用及請求刪除，惟依法本公司因執行業務所必須者，得不依您請求為之。
          </li>
        </ul>
        <h5>
          第伍條 個人資料之保護
        </h5>
        <ul>
          <li class="py-1">
            (一)
            本公司會將您的個人資料，以合於當時科技之適當方式及保護措施完整保存於我們的資料儲存系統中，防止未經授權人員接觸，以保障您的個人資料不會被不當取得或破壞。本公司管理人員均受過完整資訊保密教育，如有違反保密義務者，將受相關法律及公司內部規定之處分。如因業務需要有必要委託第三人提供服務時，亦會要求其確實遵守相關保密義務。
          </li>
          <li class="py-1">
            (二)
            本平台的網頁或功能可能提供其他網站的網路連結，您也可能經由本平台所提供的連結，點選進入其他網站或應用程式，這些連結網站或應用程式可能蒐集您的個人資料，並有其各自的隱私權政策，本政策不適用於這些連結網站或應用程式以及其資料處理措施，本平台亦不保護您於該連結網站中的隱私權。
          </li>
          <li class="py-1">
            (三)
            請您妥善保管密碼及或任何個人資料，不要將任何個人資料(尤其是密碼)提供給任何人。並請在您於本平台完成您所欲執行之程序或動作後，務必記得登出帳戶，若您是與他人共享電腦或使用公共電腦等設備，切記要關閉瀏覽器視窗，以防止他人讀取您的個人資料或信件。
          </li>
          <li class="py-1">
            (四)
            除下列情形外，本公司不會任意將您的個人資料出售、轉讓或揭露予前述第參條利用對象以外之任何人：
            (1)本公司將因法律規定、法院命令、行政調查或其他法律程序的要求而提供您的資料，惟在此情形下，該資料只會單純提供予調查單位，並受中華民國相關法律的保護；(2)為了調查和防止非法活動、涉嫌詐欺、對人身安全有潛在威脅的狀況、對本公司各式服務條款的違反，或為了對上述情形採取應對措施；(3)本公司被其他公司收購或合併，我們有權利將您的個人資料移轉給該公司。如果發生這種情況，本公司會在您的個人資料被移轉且將適用不同的隱私權政策前通知您。
          </li>
        </ul>
        <h5>
          第陸條 Cookie之使用
        </h5>
        <p>
          為了方便您的使用及提供更佳的服務，本平台會在您所使用的電腦、手機等設備中寫入並讀取Cookie(即藉由瀏覽器在您使用的電腦、手機等設備中所儲存之資訊)，以紀錄及分析使用者行為，若您不願接受Cookie的寫入，您可在使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入。但可能會導致本平台某些功能無法正常執行。
        </p>
        <h5>
          第柒條 您得自由選擇是否提供相關個人資料：
        </h5>
        <p>
          您得自由選擇是否提供相關個人資料，惟您若拒絕提供相關個人資料，本公司將無法進行必要之審核及處理作業，致無法提供您完善之相關業務或服務。
        </p>
        <h5>
          第捌條 隱私權保護政策之修正
        </h5>
        <p>
          為落實保障使用者隱私權之立意，本政策將因應需求，不過但不限於業務變化、法令遵循等，隨時進行修正。修正後，將刊登於本平台或透過其他方式通知您。建議您定期閱讀本政策，以瞭解最新內容。
        </p>
        <p>最新發布日期：中華民國109年06月20日</p>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar'
import Footer from '@/components/common/Footer'
export default {
  components: {
    Navbar,
    Footer
  }
}
</script>
